<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Jobwork Return" @loadData="loadData" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

        <div class="row">

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Process</label>
              <select ref="el_process" class="form-control select" autofocus="" required="" v-if="trans"  v-model="trans.process_id" @change="loadOrders(trans.process_id, trans.jobworker_id)"  >
                <option value="305" selected>Weaving</option>
                <option value="350">Knitting</option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Jobworker</label>
              <select id="cmbjobworker" class="form-control select" required="" v-if="trans"  v-model="trans.jobworker_id" @change="loadOrders(trans.process_id, trans.jobworker_id)"    >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">

          </div>

          <div class="col-md-4">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Delivery Date</label>
              <input id="txtDate" type="date" class="form-control" v-if="trans" v-model="trans.doc_date">
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class=" control-label font-weight-semibold">WorkOrder</label>
              <select class="form-control" v-model="trans.workorder_id"  @change="loadItem(trans.process_id, trans.jobworker_id)">
                <option v-for="order in orders" v-bind:value="order.id">
                  {{order.doc_no}}
                </option>
              </select>
            </div>
          </div>

        </div>

        <div class="row">
          <table class="table table-no-bordered" >
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th style="width: 100px;">Type</th>
              <th style="width: 120px;text-align: center;padding: 0px;">Beam</th>
              <th >Quality</th>
              <th >Color</th>
              <th style="width:120px; text-align: right;padding: 0px;">Weight</th>
              <th style="width: 30px;">Action</th>
            </tr>
            </thead>
            <tbody v-model="trans" v-for="(detail,index) in trans.list" style="max-height: 150px;overflow-y: scroll;">
              <JobworkRawmaterialReceiptRow v-bind:myrow = "detail" v-bind:colors="colors" v-bind:qualities="qualities" @value_changed="sumRowAmountTotal(idx)" @weight_changed="perform_weight_changed"  @onRemoveRow="removeRow" v-bind:myindex="index" />
            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-icon btn-rounded" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right font-weight-semibold" style="padding: 0px;" ><span >{{ rowWeightTotal | formatWeight }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>

        <div class="row align-items-end">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="trans" v-model="trans.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-1">

            <div class="form-group">
              <label for="txtvehicleno">
                Vehicle No
              </label>
              <input type="text" class="form-control text-uppercase" id="txtvehicleno" maxlength="12"  v-if="trans" v-model="trans.vehicleno"  autocomplete="off" />
            </div>

          </div>

          <div class="col-md-2">

            <div class="form-group align-self-end" >
              <label for="txtdriver" >
                Driver
              </label>
              <input type="text" class="form-control text-capitalize" id="txtdriver"   v-if="trans" v-model="trans.driver" autocomplete="off"/>
            </div>

          </div>

          <div class="col-md-3 text-right">
          </div>

          <div class="col-md-3 text-right">
            <SaveButton title="Save" @handle_save_action="savetrans"></SaveButton>
            <CloseButton title="Clear" class="ml-3" @handle_close_action="clear"></CloseButton>
            <!--            <button type="button" id="btnclose" class="btn btn-default ml-3" @click="clear" >Clear</button>-->
          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import JobworkRawmaterialReceiptRow from '@/views/fms/jobworkrawmaterialreceipt/JobworkRawmaterialReceiptRow.vue'
import {store} from '@/store/store.js'
import WindowTitleBar from '@/components/core/WindowTitleBar'
import SaveButton from '@/components/core/buttons/SaveButton'
import CloseButton from '@/components/core/buttons/CloseButton'

export default {
  name: 'JobworkRawmaterialReceiptForm',
  components: {
    CloseButton,
    SaveButton,
    WindowTitleBar,
    JobworkRawmaterialReceiptRow,
  },
  store,
  props: {
    mytrans: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":0,"finyear":2000,"doc_no":0,"doc_date":"0001-01-01","order_no":"","containertype":"","process_id":0,"jobworker_id":0,"workorder_id":0,"process":{"id":0,"name":""},"jobworker":{"id":0,"name":""},"workorder":{"id":0,"doc_no":0,"order_no":"","process_id":0,"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"name":""},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":"","list":[]},"list":[],"vehicleno":"","driver":"","remarks":""}')
    }
  },
  data () {
    return {
      readonly: false,
      orders:[],
      colors:[],
      ledgers:[],
      qualities:[],
      processes:[],
      detailItems:[],
      items : new Map(),
      rowAmountTotal: 0.0,
      rowWeightTotal: 0.0,
      trans: JSON.parse('{"id":0,"status":"Active","type":0,"finyear":2000,"doc_no":0,"doc_date":"0001-01-01","order_no":"","containertype":"","process_id":0,"jobworker_id":0,"workorder_id":0,"process":{"id":0,"name":""},"jobworker":{"id":0,"name":""},"workorder":{"id":0,"doc_no":0,"order_no":"","process_id":0,"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"name":""},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":"","list":[]},"list":[],"vehicleno":"","driver":"","remarks":""}'),
    }
  },
  created () {
    const self = this;
    Stimulsoft.Base.StiLicense.Key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn0s4gy0Fr5YoUZ9V00Y0igCSFQzwEqYBh/N77k4f0fWXTHW5rqeBNLkaurJDenJ9o97TyqHs9HfvINK18Uwzsc/bG01Rq+x3H3Rf+g7AY92gvWmp7VA2Uxa30Q97f61siWz2dE5kdBVcCnSFzC6awE74JzDcJMj8OuxplqB1CYcpoPcOjKy1PiATlC3UsBaLEXsok1xxtRMQ283r282tkh8XQitsxtTczAJBxijuJNfziYhci2jResWXK51ygOOEbVAxmpflujkJ8oEVHkOA/CjX6bGx05pNZ6oSIu9H8deF94MyqIwcdeirCe60GbIQByQtLimfxbIZnO35X3fs/94av0ODfELqrQEpLrpU6FNeHttvlMc5UVrT4K+8lPbqR8Hq0PFWmFrbVIYSi7tAVFMMe2D1C59NWyLu3AkrD3No7YhLVh7LV0Tttr/8FrcZ8xirBPcMZCIGrRIesrHxOsZH2V8t/t0GXCnLLAWX+TNvdNXkB8cF2y9ZXf1enI064yE5dwMs2fQ0yOUG/xornE";
    self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
    self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
  },
  beforeMount () {
    this.trans = this.mytrans;
    this.sumRowAmountTotal();
  },
  mounted () {
    const self = this;

    self.loadJobworkers();
    self.loadColors();
    self.loadQualities();

    if (self.$data.trans.doc_date === '0001-01-01' || self.$data.trans.doc_date === '2000-01-01'){
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
    }

    if(self.trans.jobworker_id > 0 && self.trans.process_id > 0 ) {
      self.loadOrders(self.trans.process_id, self.trans.jobworker_id);
    }

    self.$refs.el_process.focus();

  },
  filters:{
    formatWeight(val){
      return val.toFixed(3);
    }
  },
  methods:{
    setTitle() {
      return this.trans.id == 0 ? "Jobwork Raw-Material Return" : "Doc No: " + this.trans.doc_no + "  Jobwork Raw-Material Updation";
    },
    closeThis(){
      this.$emit('jobwork_return_window_closed');
      if(this.trans.id > 1){
        this.trans = JSON.parse('{"id":0,"status":"Active","type":0,"finyear":2000,"doc_no":0,"doc_date":"0001-01-01","order_no":"","containertype":"","process_id":0,"jobworker_id":0,"workorder_id":0,"process":{"id":0,"name":""},"jobworker":{"id":0,"name":""},"workorder":{"id":0,"doc_no":0,"order_no":"","process_id":0,"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"name":""},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":"","list":[]},"list":[],"vehicleno":"","driver":"","remarks":""}');
      }else{
        this.$router.push("/")
      }
    },
    loadData(){
      const self = this;
      self.loadJobworkers();

      self.loadColors();
      self.loadQualities();
    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      block_el('#mycard');

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.qualities =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        //$('#mycard').unblock();
        unblock_el('#mycard');
      });
    },
    loadColors () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      block_el('#mycard');

      self.$data.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.colors =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        unblock_el('#mycard');
      });
    },
    loadJobworkers() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      block_el('#cmbjobworker');

      //fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        $('#cmbjobworker').unblock();
        if (resp.ok){
          if(resp.data != null){
            self.$data.ledgers = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      }).finally(function () {
        unblock_el('#cmbjobworker');
      });

    },
    addRow(){
      try {
        const self = this;
        self.$data.trans.list.push(JSON.parse('{"idx":0,"container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"item_id":0,"item":{"id":0,"nature":0,"name":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"group":{"id":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0},"quality":{"id":0,"name":"","p_name":""},"rate":0,"wgt":0},"desc":"","quality_id":0,"quality":{"id":0,"name":""},"color_id":0,"color":{"id":0,"name":""},"beams":0,"gross_weight":0,"beam_weight":0,"yarn_weight":0}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) input").focus();
        }, 99);

      }catch (e) {
        alert(e);
      }
    },
    rowValueEqualizer(rowIndex, colIndex) {
      const self = this;


      let detail = self.$data.trans.list[rowIndex];
      switch (colIndex) { //  No of Beams
        case 1:
          detail.beams = parseInt(detail.beams);
          break;
        case 2: //Quality

          break;
        case 3: //Color

          break;
        case 4: //Weight
          detail.yarn_weight = parseFloat(detail.yarn_weight);

          break;
        case 5:
          // detail.taxable_amt = parseFloat(detail.taxable_amt);
          // detail.rate = (detail.taxable_amt / detail.qty).toFixed(2);
          // detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
          // detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
          break;
      }
      self.$data.rowWeightTotal = 0.0;
      self.$data.trans.list.forEach(function (det) {
        self.$data.rowWeightTotal += parseFloat(det.yarn_weight);
      });
    },
    loadItem(processid, jobworkerid){
      this.$data.trans.list=[];
    },
    find_container_byname(index, con_name){
      const self = this;

      const requestOptions = {
        method:  'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      block_el('#mycard');

      fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${con_name}`,requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok){

          if( !_.isNull(resp.data)) {
            self.$data.trans.list[index].container = resp.data;

            self.$data.trans.list[index].item_id = resp.data.item.id;
            self.$data.trans.list[index].item = resp.data.item;

            self.$data.trans.list[index].color_id = resp.data.item.color.id;
            self.$data.trans.list[index].color = resp.data.item.color;

            self.$data.trans.list[index].quality_id = resp.data.item.quality.id;
            self.$data.trans.list[index].quality = resp.data.item.quality;

            self.$data.trans.list[index].desc = resp.data.item.name;

            self.$data.trans.list[index].gross_weight = resp.data.weight;
            self.$data.trans.list[index].beam_weight = resp.data.base_weight;
            self.$data.trans.list[index].yarn_weight = resp.data.yarn_weight;

          }else{
            swal (  {title:"Oops" ,  text: "Invalid Container or Item",  type: "error"});
          }

        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:3000});
        }
        self.sumRowAmountTotal();

      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error", timer:3000});
      }).finally(function (){
        unblock_el('#mycard');
      });
    },
    loadOrders(processid, jobworkerid){

      const self = this;
      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      self.$data.orders = [];
      if(processid > 0 && jobworkerid > 0) {

        block_el('#mycard');

        // alert(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();
          if (resp.ok) {

            if (Array.isArray(resp.data)) {
              self.$data.orders = resp.data;
            }

          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          unblock_el('#mycard');
          swal({ title: "Oh noes", text: err.toString(), type: "error" });
        });
      }

    },
    sumRowAmountTotal() {
      const self = this;

      self.$data.rowAmountTotal = 0.0;
      self.$data.trans.gross_amount = 0.0;
      self.$data.rowWeightTotal = 0.0;

      self.$data.trans.list.forEach(function (detail) {
        // console.log(JSON.stringify(detail));
        self.$data.rowWeightTotal += parseFloat(detail.yarn_weight);
      });

    },
    clear(){
      const self = this;
      self.$data.trans = JSON.parse('{"id":0,"status":"Active","type":0,"finyear":2000,"doc_no":0,"doc_date":"0001-01-01","order_no":"","containertype":"","process_id":0,"jobworker_id":0,"workorder_id":0,"process":{"id":0,"name":""},"jobworker":{"id":0,"name":""},"workorder":{"id":0,"doc_no":0,"order_no":"","process_id":0,"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"name":""},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":"","list":[]},"list":[],"vehicleno":"","driver":"","remarks":""}');
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
      self.$data.trans.list = [];
      self.$data.rowAmountTotal = 0.0;
      self.$data.rowWeightTotal = 0.0;
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
    },
    removeRow(idx){
      if (idx > -1) {
        this.$data.trans.list.splice(idx, 1);
      }
    },
    perform_weight_changed(grs_wgt){
      this.sumRowAmountTotal();
    },
    savetrans(){
      const self = this;

      let mytrans = JSON.parse(JSON.stringify(self.$data.trans));

      try {
        mytrans.finyear = store.state.user.finyear;
        mytrans.status = "Active";

        mytrans.cmp_id = self.$store.state.user.company.id;
        mytrans.emp_id = self.$store.state.user.id;
        mytrans.doc_date = moment(self.$data.trans.doc_date).format('YYYY-MM-DD');
        mytrans.vehicleno = mytrans.vehicleno.toUpperCase();


        mytrans.list.forEach(function (det) {

          det.containertype = parseInt(det.containertype);

          if (det.containertype === 1) {
            det.container.id = 1;
          }else {
            det.beams = 1;
          }

          det.cont_id = det.container.id;

          if (det.quality_id < 1) {
            alert("Invalid Quality");
            return;
          } else if (det.color_id < 1) {
            alert("Invalid Color");
            return;
          } else if (det.containertype === 2 && det.item_id < 1) {
            alert("Invalid Item");
            return;
          } else if (det.yarn_weight <= 0) {
            alert("Invalid Weight");
            return;
          }

          det.gross_weight = parseFloat(det.gross_weight);
          det.beam_weight = parseFloat(det.beam_weight);
          det.yarn_weight = parseFloat(det.yarn_weight);

        });

        console.log(JSON.stringify(mytrans));

        const requestOptions = {
          method: (mytrans.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(mytrans)
        };

        block_el('#mycard');

        fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/rawmaterialreturn`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: "Success!", text: resp.msg, type: "success", timer: 3000, onClose: () => {
                self.$refs.el_process.focus();
              }
            });
            self.clear();
            self.$emit('jobwork_rawmaterialreturn_saved');
          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }

        }).catch(function (err) {
          swal({ title: "Oops", text: err.toString(), type: "error" });
        }).finally(function () {
          unblock_el('#mycard');
        });
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>

input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}


</style>
